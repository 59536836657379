import "../App.css";

function LoadingScreen() {
  return (
    <div id="Loading">
      <h1>Loading....</h1>
    </div>
  );
}

export default LoadingScreen;